import Button from "components/Button";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import {
  useCourseDetailApi,
  useCourseGetStatus,
  useCourseSetLessonDone,
} from "../courseApi";
import {
  CourseStatus,
  getLessonNumberBySlug,
  getNextLesson,
  useCourseStore,
} from "../courseStore";
import { trackEvent } from "lib/amplitude";
import { formatCurrency } from "lib/currency";
import { useAuthStore } from "modules/Auth/authStore";
import useTranslation from "next-translate/useTranslation";
import { useIsSubdomain, useIsTenant } from "lib/host";

export const EndedOverlay: React.FC<{ onClickNext: () => void }> = (props) => {
  const { t } = useTranslation("course");
  const router = useRouter();
  const courseSlug =
    typeof router.query.courseSlug == "string" ? router.query.courseSlug : "";

  const lessonSlug =
    typeof router.query.lesson == "string" ? router.query.lesson : "";

  const bundleSlug =
    typeof router.query.lesson == "string" ? router.query.bundle : "";

  const { setCurrentTab, currentTab, setShowModalQuiz, currentCourse } =
    useCourseStore((s) => ({
      setCurrentTab: s.setCurrentTab,
      currentTab: s.currentTab,
      setShowModalQuiz: s.setShowModalQuiz,
      currentCourse: s.currentCourse,
    }));

  const currentUser = useAuthStore((state) => state.currentUser);
  const isEnterprisePlus =
    currentUser?.tenant !== undefined &&
    [
      "Enterprise Plus",
      "Pro Plus",
      `Pro Custom (${currentUser?.referral})`,
    ].includes((currentUser?.tenant as any)?.plan?.name);

  const courseChapters = currentCourse?.chapters || [];
  const courseSetLessonDone = useCourseSetLessonDone();
  const courseGetStatus = useCourseGetStatus(courseSlug);

  const isCourseFree: boolean =
    // @ts-ignore
    currentCourse?.discounted_price === 0 ||
    // @ts-ignore
    currentCourse?.original_price === 0;

  const isB2B = useIsSubdomain() || useIsTenant();
  const isAnyQuizLesson =
    (currentCourse as any)?.chapters &&
    (currentCourse as any)?.chapters.some((a) =>
      a.lessons.some((x) => x.type === "Quiz")
    );
  const nextLesson = getNextLesson(courseChapters as any, lessonSlug);

  const [
    courseStatus,
    setPaywallShown,
    currentLesson,
    courseEnrollmentId,
    setCourseStatus,
  ] = useCourseStore((c) => [
    c.courseStatus,
    c.setPaywallShown,
    c.currentLesson,
    c.courseEnrollmentId,
    c.setCourseStatus,
  ]);

  const hasUserPaid = courseStatus?.is_paid;
  const hasPaywall: boolean =
    currentCourse?.is_paywall_enabled === undefined
      ? true
      : currentCourse?.is_paywall_enabled;
  const [counter, setCounter] = useState(1);

  // TODO: Only use if countdown being used again
  // useEffect(() => {
  //   let setCounterInterval;

  //   if ( (nextLesson?.is_free && nextLesson?.type !== "Webinar") || (!nextLesson?.is_free && hasUserPaid)) {
  //     setCounterInterval = setInterval(() => {
  //       setCounter((c) => c - 1);
  //     }, 1000);
  //   } else {
  //     clearInterval(setCounterInterval);
  //   }

  //   if(counter==0) {
  //     courseSetLessonDone.doFetch(
  //       {
  //         slug: lessonSlug,
  //       },
  //       {
  //         onSuccess(data) {
  //           courseGetStatus.doFetch({
  //             slug: courseSlug,
  //           });
  //         },
  //       }
  //     );
  //   }

  //   return () => clearInterval(setCounterInterval);
  // }, [nextLesson]);

  const redirectNextLesson = () => {
    props.onClickNext();
    if (nextLesson) {
      let queriesCourse = {
        courseSlug: courseSlug,
        lesson: nextLesson?.slug,
      };
      /// Set showmodalquiz in store to Show Quiz directly after countdown
      if (nextLesson.type == "Quiz" || nextLesson.type == "Assignment") {
        setShowModalQuiz(true);
      }

      router.replace(
        nextLesson.type == "Webinar" && !hasUserPaid
          ? {
              pathname: "/course/webinar/[courseSlug]/[lessonSlug]",
              query: {
                courseSlug: courseSlug,
                lessonSlug: nextLesson?.slug,
              },
            }
          : {
              pathname: "/course/[courseSlug]",
              query: queriesCourse,
            },
        undefined,
        {
          shallow: true,
        }
      );
    }
  };

  const isSubdomainTenant = useIsSubdomain();
  const isTenatURL = useIsTenant();

  const getAccess = (lesson: any) => {
    if (isSubdomainTenant || isTenatURL) {
      if (lesson.is_free || isEnterprisePlus || hasUserPaid) return true;
      else if (lesson.is_premium || !isEnterprisePlus) return false;
    } else {
      if (!lesson.is_free || lesson.is_premium) return false;
      else if (lesson.is_free || hasUserPaid) return true;
    }
  };

  useEffect(() => {
    // if (counter === 0) {
    /// Hit API Course done & redirect directly after video finished
    courseSetLessonDone.doFetch(
      {
        slug: lessonSlug,
      },
      {
        onSuccess(data) {
          courseGetStatus.doFetch({
            slug: courseSlug,
          });
        },
      }
    );

    if (getAccess(nextLesson)) redirectNextLesson();

    // }
  }, [counter]);

  useEffect(() => {
    // if (!isCourseFree && !nextLesson?.is_free && !hasUserPaid) {
    //   setPaywallShown(true);
    // }
    if (
      (!isCourseFree &&
        (!nextLesson?.is_free ||
          !nextLesson ||
          nextLesson?.type == "Webinar") &&
        !hasUserPaid) ||
      (!nextLesson && isCourseFree)
    ) {
      if (currentTab !== "Rekomendasi Kursus Lainnya") {
        setCurrentTab("Rekomendasi Kursus Lainnya");

        trackEvent({
          event: "lms_recommended_auto_a",
          property: {
            course_slug: courseSlug,
            lesson_slug: lessonSlug,
          },
        });
      }
    }
  }, [nextLesson?.is_free, nextLesson?.type, hasUserPaid, isCourseFree]);

  useEffect(() => {
    if (!nextLesson) {
      courseSetLessonDone.doFetch(
        {
          slug: lessonSlug,
        },
        {
          onSuccess(data) {
            courseGetStatus.doFetch(
              { slug: courseSlug },
              {
                onSuccess(data) {
                  setCourseStatus(data?.data as CourseStatus);
                },
              }
            );
          },
        }
      );
    }
  }, []);

  return (
    <div
      className="w-full h-full relative text-white_to_white bg-no-repeat bg-center bg-cover xxl-max:h-[555px] xxxl:h-[588px]"
      style={{
        backgroundImage: courseStatus?.image_cover_url
          ? `url(${courseStatus?.image_cover_url})`
          : "",
      }}
    >
      {(nextLesson?.is_free && nextLesson?.type !== "Webinar") ||
      (!nextLesson?.is_free && hasUserPaid) ? (
        <div className="h-full w-full bg-[#000000b3] text-white_to_white absolute p-40 pl-[10%] flex flex-col items-start justify-center">
          <div className="text-c1 font-light">
            Materi Selanjutnya dalam{" "}
            <span className="font-medium">{counter}</span>
          </div>
          <div className="h-12"></div>
          <div className="text-b1 font-medium">{nextLesson?.name}</div>
          <div className="h-12"></div>
          <div className="flex items-center gap-6">
            {/* <Link
              onClick={() => {
                // @ts-ignore
                trackEvent({
                  event: "lms_video_next_a",
                  property: {
                    course_slug: courseSlug,
                    lesson_slug: lessonSlug,
                  },
                });
                trackEvent({
                  // @ts-ignore
                  event: "lms_lesson_view_a",
                  property: {
                    course_slug: courseSlug,
                    lesson_slug: currentLesson?.slug,
                    course_enrollment_id: courseEnrollmentId,
                    type: currentLesson?.type,
                    bundle_slug: bundleSlug,
                    //@ts-ignore
                    lesson_num: getLessonNumberBySlug(currentLesson?.slug, courseStatus)
                  },
                });
                setShowModalQuiz(true);
                props.onClickNext();
              }}
              shallow
              href={
                nextLesson?.type == "Webinar" && !hasUserPaid
                  ? {
                      pathname: "/course/webinar/[courseSlug]/[lessonSlug]",
                      query: {
                        courseSlug: courseSlug,
                        lessonSlug: nextLesson?.slug,
                      },
                    }
                  : {
                      pathname: "/course/[courseSlug]",
                      query: {
                        courseSlug: courseSlug,
                        lesson: nextLesson?.slug,
                      },
                    }
              }
            > */}
            <Button
              variant="white"
              className="rounded-2"
              onClick={() => {
                redirectNextLesson();
              }}
            >
              {t("startNow")}
            </Button>
            {/* </Link> */}
            {/* <div className="text-c1 font-light">Cancel</div> */}
          </div>
        </div>
      ) : !isCourseFree &&
        (!(nextLesson?.is_free === false) ||
          !nextLesson ||
          nextLesson?.type === "Webinar") &&
        !hasUserPaid &&
        hasPaywall &&
        !isEnterprisePlus &&
        // Hide certificate option if b2b & doesn't have quiz type lesson
        (!isB2B || isAnyQuizLesson) ? (
        <div className="h-full w-full bg-[#000000b3] text-white_to_white absolute p-40 pl-[10%] flex flex-col items-start justify-center">
          <div className="text-b1 font-medium">
            Dapatkan Sertifikat Resmi {/* @ts-ignore */}
            {currentCourse?.institution?.name} hanya dengan Rp
            {formatCurrency(
              //  @ts-ignore

              currentCourse?.discounted_price ||
                //@ts-ignore
                currentCourse?.original_priceoriginal_price
            )}
            !
          </div>
          <div className="h-12"></div>
          <Button
            variant="white"
            className="text-neutral-80 rounded-2"
            onClick={() => {
              setPaywallShown(true);
            }}
          >
            Upgrade Kursus
          </Button>
        </div>
      ) : !isCourseFree &&
        !nextLesson &&
        courseStatus?.course_lesson_count - courseStatus?.course_lesson_done >
          1 &&
        hasPaywall &&
        // Hide certificate option if b2b & doesn't have quiz type lesson
        (!isB2B || isAnyQuizLesson) ? (
        <div className="h-full w-full bg-[#000000b3] text-white_to_white absolute p-40 pl-[10%] flex flex-col items-start justify-center">
          <div className="text-b2">
            Selesaikan pelajaranmu dan dapatkan sertifikat!
          </div>
        </div>
      ) : !nextLesson &&
        courseStatus?.course_lesson_count ===
          courseStatus?.course_lesson_done ? (
        <div className="h-full w-full bg-[#000000b3] text-white_to_white absolute p-40 pl-[10%] flex flex-col items-start justify-center">
          <div className="text-b2">Materi telah selesai</div>
          <div className="h-12"></div>
          <Button
            variant="white"
            className="text-neutral-80 rounded-2"
            onClick={() =>
              router.replace({
                pathname: "/course/[courseSlug]",
                query: {
                  courseSlug,
                },
              })
            }
          >
            {t("rewatch")}
          </Button>
        </div>
      ) : (
        <div className="h-full w-full bg-[#000000b3] text-white_to_white absolute p-40 pl-[10%] flex flex-col items-start justify-center">
          <div className="text-b2">Materi telah selesai</div>
          <div className="h-12"></div>
          <Button
            variant="white"
            className="text-neutral-80 rounded-2"
            onClick={() =>
              router.replace({
                pathname: "/course/[courseSlug]",
                query: {
                  courseSlug,
                },
              })
            }
          >
            {t("rewatch")}
          </Button>
        </div>
      )}
    </div>
  );
};
